import { motion } from 'framer-motion';
import { text } from 'framer-motion/client';
import { useState, useEffect } from 'react';

export default function Hero() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const greetings = [
    { text: 'Hello! My name is,', flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿' }, // English
    { text: 'Hallo ich heiße,', flag: '🇩🇪' }, // German
    { text: 'Bonjour mon nom est,', flag: '🇫🇷' }, // French
    { text: 'Hola mi nombre es,', flag: '🇪🇸' }, // Spanish
    { text: 'Bula! Na yacaqu o,', flag: '🇫🇯' }, // Fijian
    { text: 'Γεια σας το όνομά μου είναι,', flag: '🇬🇷' }, // Greek
    { text: 'Hej mitt namn är,', flag: '🇸🇪' }, // Swedish
    { text: 'Dobrý den, jmenuji se,', flag: '🇨🇿' }, // Czech
    { text: 'Ciao mi chiamo,', flag: '🇮🇹' }, // Italian
    { text: 'Olá meu nome é,', flag: '🇵🇹' }, // Portuguese
    { text: 'Dia dhuit is ainm dom,', flag: '🇮🇪' }, // Irish
    { text: 'Halò is ainm dhomh,', flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿' }, // Scottish Gaelic
    { text: 'Helo fy enw i yw,', flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿' }, // Welsh
    { text: 'Grüezi, min Nomme isch,', flag: '🇨🇭' }, // Swiss
  ];

  const greetingCount = greetings.length;

  // Interval for changing greetings
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % greetingCount);
    }, 3000);

    return () => clearInterval(interval);
  }, [greetingCount]);

  return (
    <div>
      <div className="flex h-screen items-center justify-center px-4 md:px-0">
        <div className="flex flex-row items-center justify-center">
          <div className="flex flex-col justify-center">
            <motion.div
              className="relative text-center font-PPEditorial text-lg text-main-text-colour md:text-2xl"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <motion.div
                className="absolute left-0 right-0 m-auto text-center text-4xl"
                animate={{
                  opacity: isHovered ? 1 : 0,
                  y: isHovered ? -40 : 10,
                }}
                transition={{ duration: 0.1 }}
              >
                {greetings[currentWordIndex].flag}
              </motion.div>
              <motion.div
                key={currentWordIndex}
                initial={{ opacity: 1, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.1 }}
              >
                {greetings[currentWordIndex].text}
              </motion.div>
            </motion.div>
            <div className="motion-preset-expand pt-4 text-center font-PPEditorial text-5xl italic text-main-text-colour md:text-7xl lg:text-9xl">
              Mitchel Mckee
            </div>
            <div className="motion-preset-expand text-center font-PPEditorial text-base text-main-text-colour motion-delay-500 md:text-xl lg:text-2xl">
              Full stack developer based in the UK
            </div>
          </div>
        </div>
      </div>
      {/* <div className="hidden md:block">
        <Carousel />
      </div> */}
    </div>
  );
}
