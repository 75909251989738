import React, { useRef, useState, useEffect } from 'react';

const Navbar = ({ refs }) => {
  const [highlightStyle, setHighlightStyle] = useState({
    width: 0,
    transform: 'translateX(0)',
  });
  const [currentSection, setCurrentSection] = useState('');
  const navRef = useRef(null);

  const handleMouseEnter = (e) => {
    updateHighlightPosition(e.target);
  };

  const updateHighlightPosition = (element) => {
    if (element && navRef.current) {
      const itemRect = element.getBoundingClientRect();
      const navRect = navRef.current.getBoundingClientRect();
      const translateX =
        itemRect.left - navRect.left - navRect.width / 2 + itemRect.width / 2;

      setHighlightStyle({
        width: itemRect.width,
        height: itemRect.height,
        transform: `translateX(${translateX}px)`,
      });
    }
  };

  const handleScrollTo = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions,
    );

    Object.values(refs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(refs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [refs]);

  useEffect(() => {
    const currentItem = document.querySelector(
      `[data-section="${currentSection}"]`,
    );
    if (currentItem) {
      updateHighlightPosition(currentItem);
    }
  }, [currentSection]);

  const menuItems = [
    { name: 'Home', ref: refs.homeRef },
    { name: 'About', ref: refs.aboutRef },
    { name: 'Projects', ref: refs.projectsRef },
    { name: 'Contact', ref: refs.contactRef },
  ];

  return (
    <nav
      ref={navRef}
      className="fixed bottom-4 left-1/2 z-10 mt-4 flex -translate-x-1/2 transform items-center justify-center rounded-full bg-gray-300 px-4 py-2 motion-ease-spring-bouncier motion-scale-in-[20%] motion-translate-y-in-150"
    >
      <div
        className="absolute z-0 rounded-full bg-gray-400 opacity-20 transition-all duration-300"
        style={highlightStyle}
      ></div>
      {menuItems.map((item, index) => (
        <a
          key={index}
          href="#"
          data-section={item.ref.current?.id}
          onMouseEnter={handleMouseEnter}
          onClick={(e) => {
            e.preventDefault();
            handleScrollTo(item.ref);
          }}
          className="motion-duraction-850 relative z-10 rounded-full px-4 py-2 text-center font-PPEditorial motion-delay-[300ms] motion-ease-spring-bouncier motion-translate-y-in-50 motion-opacity-in"
        >
          {item.name}
        </a>
      ))}
    </nav>
  );
};

export default Navbar;
