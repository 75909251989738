import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';

import reactLogo from '../assets/React.svg';
import tailwindLogo from '../assets/TailwindCSS.svg';
import pythonLogo from '../assets/Python.svg';
import figmaLogo from '../assets/Figma.svg';
import javaLogo from '../assets/Java.svg';
import photoshopLogo from '../assets/Photoshop.svg';
import swiftLogo from '../assets/Swift.svg';
import cppLogo from '../assets/CPP.svg';
import cloudflarePagesLogo from '../assets/Cloudflare.svg';

const stackInfo = [
  {
    title: 'React',
    description:
      'React is where I feel the most comfortable, it is what I primarilly use to build web applications. All of the projects in my portfolio are built using React for at least some part of the application (this site included!). ',
  },
  {
    title: 'TailwindCSS',
    description:
      'Tailwind is my go-to CSS framework, most projects in this portfolio use it in some way, the inline styles make creating responsive designs simple and understandable meaning I can spend more time on the important stuff and I can leave behind readable code.',
  },
  {
    title: 'Python',
    description:
      'I have spent the most time with Python, it was my first language and I have used it for a variety of projects from web scraping to machine learning. Python is also used in a majority of my projects for backend tasks. It was used heavily throughout my degree.',
  },
  {
    title: 'Figma',
    description:
      'Figma is my current go-to design, prototyping and wireframing tool. Creating mockups is an important part of my process and Figma is the tool I have found to be the most efficient and effective for my workflow.',
  },
  {
    title: 'Java',
    description:
      'Java was used heavily throughout my degree, I used it a lot when learning the fundamentals of programming. I have a good understanding of the language and have used it in a few projects, over the years.',
  },
  {
    title: 'Photoshop',
    description:
      'For more complex and detailed designs my default tool is Photoshop. I have used it for years and have a good understanding of the tool. I use it for more intricate and detailed designs that can be used in projects.',
  },
  {
    title: 'Swift',
    description:
      "Creating apps for iOS isn't something I do often but when I do I use Swift. I have a good understanding of the language and have used it in a few projects. I used XCode and Swift for my final year project at university.",
  },
  {
    title: 'C++',
    description:
      'I used C++ while working in my junior software engineer role. Therefore I have a good understanding of the language especially when it comes to very large codebases and performance critical applications.',
  },
  {
    title: 'Cloudflare Pages',
    description:
      'Cloudflare Pages is my go-to for hosting my web applications. It is simple to use and I can use it to get a new project online in minutes. I can confidently deploy my projects knowing they are secure and fast.',
  },
];

const images = [
  reactLogo,
  tailwindLogo,
  pythonLogo,
  figmaLogo,
  javaLogo,
  photoshopLogo,
  swiftLogo,
  cppLogo,
  cloudflarePagesLogo,
];

const timeEmojis = {
  '00:00': '🕛',
  '00:30': '🕧',
  '01:00': '🕐',
  '01:30': '🕜',
  '02:00': '🕑',
  '02:30': '🕝',
  '03:00': '🕒',
  '03:30': '🕞',
  '04:00': '🕓',
  '04:30': '🕟',
  '05:00': '🕔',
  '05:30': '🕠',
  '06:00': '🕕',
  '06:30': '🕡',
  '07:00': '🕖',
  '07:30': '🕢',
  '08:00': '🕗',
  '08:30': '🕣',
  '09:00': '🕘',
  '09:30': '🕤',
  '10:00': '🕙',
  '10:30': '🕥',
  '11:00': '🕚',
  '11:30': '🕦',
  '12:00': '🕛',
  '12:30': '🕧',
  '13:00': '🕐',
  '13:30': '🕜',
  '14:00': '🕑',
  '14:30': '🕝',
  '15:00': '🕒',
  '15:30': '🕞',
  '16:00': '🕓',
  '16:30': '🕟',
  '17:00': '🕔',
  '17:30': '🕠',
  '18:00': '🕕',
  '18:30': '🕡',
  '19:00': '🕖',
  '19:30': '🕢',
  '20:00': '🕗',
  '20:30': '🕣',
  '21:00': '🕘',
  '21:30': '🕤',
  '22:00': '🕙',
  '22:30': '🕥',
  '23:00': '🕚',
  '23:30': '🕦',
};

const weatherEmojis = {
  'Clear': '☀️',
  'Sunny': '☀️',
  'Partly cloudy': '⛅',
  'Cloudy': '☁️',
  'Overcast': '☁️',
  'Mist': '🌫️',
  'Patchy rain possible': '🌦️',
  'Patchy snow possible': '🌨️',
  'Patchy sleet possible': '🌨️',
  'Patchy freezing drizzle possible': '🌨️',
  'Thundery outbreaks possible': '🌩️',
  'Blowing snow': '🌬️',
  'Freezing fog': '🌫️',
  'Patchy light drizzle': '🌦️',
  'Light drizzle': '🌦️',
  'Freezing drizzle': '🌨️',
  'Heavy freezing drizzle': '🌨️',
  'Patchy light rain': '🌦️',
  'Light rain': '🌧️',
  'Moderate rain at times': '🌧️',
  'Moderate rain': '🌧️',
  'Heavy rain at times': '🌧️',
  'Heavy rain': '🌧️',
  'Light freezing rain': '🌨️',
  'Moderate or heavy freezing rain': '🌨️',
  'Light sleet': '🌨️',
  'Moderate or heavy sleet': '🌨️',
  'Patchy light snow': '🌨️',
  'Light snow': '🌨️',
  'Patchy moderate snow': '🌨️',
  'Moderate snow': '🌨️',
  'Patchy heavy snow': '❄️',
  'Heavy snow': '❄️',
  'Ice pellets': '🌨️',
  'Light rain shower': '🌧️',
  'Moderate or heavy rain shower': '🌧️',
  'Torrential rain shower': '🌧️',
  'Light sleet showers': '🌨️',
  'Moderate or heavy sleet showers': '🌨️',
  'Light snow showers': '🌨️',
  'Moderate or heavy snow showers': '❄️',
  'Light showers of ice pellets': '🌨️',
  'Moderate or heavy showers of ice pellets': '❄️',
  'Patchy light rain with thunder': '⛈️',
  'Moderate or heavy rain with thunder': '⛈️',
  'Patchy light snow with thunder': '⛈️',
  'Moderate or heavy snow with thunder': '⛈️',
};

const getClosestEmoji = (time) => {
  const minutes = moment.duration(time).asMinutes();
  const closest = Object.keys(timeEmojis).reduce((prev, curr) => {
    const prevDiff = Math.abs(moment.duration(prev).asMinutes() - minutes);
    const currDiff = Math.abs(moment.duration(curr).asMinutes() - minutes);
    return currDiff < prevDiff ? curr : prev;
  });
  return timeEmojis[closest];
};

const getWeatherEmoji = (condition) => {
  return weatherEmojis[condition] || '🌈'; // Default to a rainbow emoji if condition not found
};

export default function About() {
  const [weather, setWeather] = useState(null);
  const [error, setError] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [hoveredTechStack, setHoveredTechStack] = useState(stackInfo[0]);
  const [isRotating, setIsRotating] = useState(true);

  useEffect(() => {
    fetchWeather();
    const timeIntervalId = setInterval(updateTime, 1000);
    const techStackIntervalId = setInterval(rotateTechStack, 5000);
    return () => {
      clearInterval(timeIntervalId);
      clearInterval(techStackIntervalId);
    };
  }, [hoveredTechStack]);

  const rotateTechStack = () => {
    if (!isRotating) return;
    const currentIndex = stackInfo.indexOf(hoveredTechStack);
    const nextIndex = (currentIndex + 1) % stackInfo.length;
    setHoveredTechStack(stackInfo[nextIndex]);
  };

  const updateTime = () => {
    const ukTime = moment().tz('Europe/London').format('HH:mm');
    const emoji = getClosestEmoji(moment().tz('Europe/London').format('HH:mm'));
    setCurrentTime(`${emoji} ${ukTime}`);
  };

  const fetchWeather = async () => {
    const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
    const location = 'newcastle upon tyne';
    const url = `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${location}`;

    try {
      const response = await axios.get(url);
      setWeather(response.data);
      setError('');
    } catch (err) {
      setError('Failed to fetch weather data');
      setWeather(null);
    }
  };

  return (
    <div className="m-4 flex h-screen flex-col items-center justify-center rounded-3xl p-6 md:flex-row">
      <div className="md:1/2 flex flex-col items-center justify-center">
        <div className="mb-4 flex flex-col items-center justify-center space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          <div className="flex h-40 w-full flex-col space-y-4 rounded-3xl bg-main-bg-colour p-4 md:w-1/2">
            {weather ? (
              <>
                <div className="text-center font-PPEditorial">
                  {getWeatherEmoji(weather.current.condition.text)}{' '}
                  {weather.current.temp_c}°C &nbsp;
                  {currentTime}
                </div>
              </>
            ) : (
              <div>{error}</div>
            )}
            <div className="text-center font-PPEditorial text-2xl font-extrabold text-main-text-colour">
              NEWCASTLE UPON-TYNE <br /> AVAILABLE FOR WORK
            </div>
          </div>
          <div className="relative flex h-40 w-full items-center justify-center rounded-3xl bg-main-bg-colour p-4 md:w-1/2">
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-[8rem] opacity-10">🎓</div>
            </div>
            <div className="relative text-center font-PPEditorial text-xl font-extrabold text-main-text-colour">
              COMPUTER SCIENCE WITH ARTIFICIAL INTELLIGENCE BSC (HONS)
            </div>
          </div>
        </div>
        <div className="flex h-80 max-w-screen-lg flex-col rounded-3xl bg-main-bg-colour p-4">
          <div className="text-center font-PPEditorial text-3xl italic text-main-text-colour">
            My Tech Stack
          </div>
          {/* This div will not render on mobile */}
          <div className="mt-4 hidden flex-wrap items-center justify-center space-x-1 md:flex">
            {images.map((image, index) => (
              <div key={index} className="w-1/8 flex flex-col items-center">
                <img
                  src={image}
                  alt="logo"
                  onClick={() => {
                    setHoveredTechStack(stackInfo[index]);
                    setIsRotating(false);
                  }}
                  className={`w-full transition-transform duration-200 ${hoveredTechStack.title === stackInfo[index].title ? 'scale-110' : 'scale-95'} cursor-pointer`}
                />
              </div>
            ))}
          </div>

          <div>
            {hoveredTechStack && (
              <div className="mt-4 overflow-y-auto md:ml-6">
                <h1 className="pb-2 text-center font-PPEditorial text-lg italic text-main-text-colour md:text-start">
                  {hoveredTechStack.title}
                </h1>
                <p className="text-center font-PPEditorial text-sm text-main-text-colour md:text-start">
                  {hoveredTechStack.description}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full pt-10 md:ml-6 md:mt-0 md:w-1/2 md:pt-0 md:text-left">
        <h1 className="pl-4 text-center font-PPEditorial text-4xl italic text-main-bg-colour md:text-start md:text-8xl">
          About
        </h1>
        <p className="font-PPEditorial text-lg text-main-bg-colour md:text-xl">
          I'm Mitchel 👋, I love to build web experiences that are both
          beautiful and functional. I'm currently working on&nbsp;
          <span className="font-bold text-olenium-blue">
            <a
              href="https://olenium.co.uk"
              className="transition-all hover:text-2xl"
            >
              Olenium
            </a>
          </span>
          , creating seamless freelancer to client experiences. I believe the
          key to amazing design is a devotion to the{' '}
          <span className="font-bold italic">details.</span>
        </p>
      </div>
    </div>
  );
}
